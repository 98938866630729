<template>
  <app-modal title="Batch registration" :width="900" @submit="onSubmit">
    <v-alert class="mb-6" title="Import attendees CSV">
      <p class="text-subtle">
        Use the following
        <button class="text-primary font-semibold" @click="downloadTemplate">
          template
        </button>
        to enforce compliant data.
      </p>
      <template #append><v-btn @click="open">Import</v-btn></template>
    </v-alert>

    <v-alert
      v-if="errors.length > 0"
      color="error"
      title="Your CSV contains the following errors:"
      variant="tonal"
    >
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </v-alert>

    <v-table v-if="lines.length > 0" density="compact">
      <thead>
        <tr>
          <th
            v-for="key in Object.keys(lines[0] ?? {})"
            :key="key"
            class="!font-semibold"
          >
            {{ key }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="line in lines"
          :key="line.email"
          :class="{ 'text-subtle bg-gray-50': isAlreadyRegistered(line) }"
        >
          <td v-for="(value, index) in Object.values(line)" :key="index">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </v-table>

    <template #actions>
      <v-card-actions class="!flex justify-end !p-4 !pt-10">
        <v-btn size="large" @click="modal.close()"> Cancel </v-btn>
        <v-btn
          color="primary"
          :disabled="linesToBeSubmitted.length === 0"
          :loading="status === 'pending'"
          size="large"
          type="submit"
          variant="elevated"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </template>
  </app-modal>
</template>

<script lang="ts" setup>
import type { SessionPageView } from "~/pages/sessions/[sessionId]/_includes/composables/session-view.hook";
import { BatchRegistrationLineSchema } from "~~/model/registration.model";

const properties = defineProps<{
  session: SessionPageView;
}>();

const lines = ref<
  Pick<
    DatabaseInsertRequest<"users">,
    "first_name" | "last_name" | "email" | "job_title"
  >[]
>([]);

const errors = ref<string[]>([]);

function isAlreadyRegistered(line: (typeof lines.value)[number]) {
  return properties.session.attendees.some(
    (attendee) =>
      attendee.user.email.toLowerCase().trim() ===
      line.email.toLowerCase().trim(),
  );
}

const linesToBeSubmitted = computed(() =>
  lines.value.filter((line) => !isAlreadyRegistered(line)),
);

function downloadTemplate() {
  return toCsvDownload(
    [
      {
        first_name: "",
        last_name: "",
        job_title: "",
        email: "",
      },
    ],
    {
      escapeValues: false,
      fileName: "batch-template",
    },
  );
}

const { onChange, open } = useFileDialog({
  accept: "text/csv",
});

onChange((list) => {
  if (!list) return;

  processCsv([...list]);
});

async function processCsv(files: File[] | null) {
  if (!files?.length) return;

  const content = files[0] ? await files[0].text() : "";

  const [keys, ...rows] = content
    .split("\n")
    .map((l) => l.trim())
    .filter(Boolean);

  const result = BatchRegistrationLineSchema.safeParse(
    rows.map((line) =>
      line.split(/[,;]/).reduce(
        (obj, value, index) => {
          obj[(keys ?? "").split(/[,;]/)[index]!] = value || undefined;

          return obj;
        },
        {} as Record<string, string | undefined>,
      ),
    ),
  );

  if (result.success) {
    lines.value = result.data;
    errors.value = [];
  } else {
    lines.value = [];
    errors.value = result.error.errors.map(
      (error) =>
        `Row ${error.path[0]} : ${
          error.path[1]
        } ${error.message.toLowerCase()}`,
    );
  }
}

const modal = useModal("batchRegistrationModal");
const { execute: onSubmit, status } = useFetch(
  `/api/courses/${properties.session.course_id}/sessions/${properties.session.id}/register/batch`,
  {
    method: "POST",
    body: linesToBeSubmitted,
    watch: false,
    immediate: false,
    onResponse(payload) {
      if (payload.response.status >= 400) {
        return useAlert().showError(payload.response._data.message);
      }

      modal.close({ confirmed: true });
      useAlert().showSuccess("Users registered !");
    },
  },
);
</script>
